<template>
  <div class="company-dialog">
    <b-modal id="modalSupport"  size="lg" centered modal-class="modal-warning dialog-1000 custom-dialog"
      @ok="ok"  @close="close" no-close-on-esc no-close-on-backdrop
      ok-variant="warning"  :ok-disabled="loadingFile"
      ok-only
      :title="title">
      <div class="mt-0">
        <form-render :fields.sync="fields" 
          :form.sync="formCards" 
          @send="onAccept"
          ref="formRenderCards"
          :disableAll="loading"
          :key="keyFormRender">
          <template #slot1>
            <div>
              <b-media-body class="mt-2">
                <b-form-file
                  ref="refInputEl"
                  placeholder="Sube tu archivo aquí"
                  v-model="cardFile"
                  accept=".pdf, .doc, .docx, .xlxs"
                  :hidden="!true"
                />
                <small :class="fileUpload.class">{{fileUpload.text}} <feather-icon :icon="fileUpload.icon" :class="fileUpload.icon === 'LoaderIcon' ? 'spinner' : ''"/></small>
                <!--/ upload button -->
              </b-media-body>
            </div>
          </template>
        </form-render>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {

  props: ['title', 'form'],
  data() {
    return {
      fields: [],
      formCards: {},
      loading: false,
      keyFormRender: 0,
      collection: 'support',
      cardFile: null,
      loadingFile: false,
      disableUrlInput: false,
      showUploadFile: false,
      optionsCheck: [{id: 1, text: 'Cargar Archivos'}],
      fileUpload: {}
    }
  },
  computed: {
    ...mapGetters({
      globalData: 'getGlobalData'
    })
  },
  mounted() {
    this.initializeData()
  },
  watch: {
    form: {
      handler () {
        this.fileUpload = {}
        this.formCards = {
          ...this.form,
          typeFile: [], //this.form.typeFile ? this.optionsCheck : [],
          country: ((this.globalData?.countries || []).map(el => ({...el, id: el.code.toLowerCase()}))).find(el => el.code.toLowerCase() === (this.form?.country || '').toLowerCase())
        }
        this.initializeData()
        // this.changeTypeFile('typeFile', this.formCards.typeFile)
      },
      deep: true
    },
    cardFile () {
      this.uploadToStorage()
    }
  },
  methods:{
    setSelectorOptions (name, options) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].options = options
      this.keyFormRender++
      
    },
    ok(e) {
      e.preventDefault()
      this.$refs.formRenderCards.checkForm()
    },
    close (e) {
      const isValid = ['title', 'country', 'order', 'url'].every(el => {
        return (this.formCards[el]?.id || this.formCards[el]) === (this.form[el]?.id || this.form[el])
      })
      if (isValid) {
        this.closeModal()
      } else {
        e.preventDefault()
        this.$yesno(this.$t('msg-unsaved-card'), this.closeModal, this.$t('title-unsaved-card'))
      }
    },
    closeModal () {
      this.$bvModal.hide('modalSupport')
      this.formCards = {}
      this.$emit('update:form', {})
    },
    onAccept(finalForm) {
      this.loading = true
      const form = {
        ...finalForm, 
        parent: this.form.parent,
        img:{
          height: this.form.img.height,
          // url: this.form.img.url,
          name: this.form.img.name,
          width: this.form.img.width
        },
        country: finalForm.country?.code.toLowerCase() || null,
        color: this.form.color || null,
        textColor: this.form.textColor || null,
        typeFile: this.form.typeFile?.length !== 0
      }

      this.$root.$data.db.collection(this.collection).doc(this.form.id).set(form)
        .then(() => {
          this.loading = false
          this.$bvModal.hide('modalSupport')
        })
        .catch(err => {
          this.loading = false
          this.$alert(this.$t('msg-problema-guardar', {code: err}))
        })
      this.showUploadFile = false
    },
    initializeData() {
      this.fields = [
        {fieldType: 'FieldInput', name: 'title', label: 'Título', validation: 'required', clearable: true, containerClass: 'container-info col-sm-12 col-md-6'},
        {fieldType: 'FieldSelect', name: 'country', label: 'País', clearable: true, containerClass: 'container-info col-sm-12 col-md-6'},
        {fieldType: 'FieldInput', type: 'number', name: 'order', label: 'Orden', clearable: true, containerClass: 'container-info col-sm-12 col-md-6'},
        {fieldType: 'FieldCheckbox', multiple: true, name: 'typeFile', containerClass: 'container-info col-12 col-md-6 mt-2', options: this.optionsCheck, change: this.changeTypeFile},
        {fieldType: 'FieldInput', name: 'url', label: 'URL', validation: 'required', clearable: true, disabled: this.disableUrlInput, containerClass: 'col-12 container-info'}
      ]
      this.setSelectorOptions('country', (this.globalData?.countries || []).map(el => ({...el, id: el.code.toLowerCase()})))
    },
    uploadToStorage() {
      this.fileUpload.text = 'Subiendo archivo'
      this.fileUpload.icon = 'LoaderIcon'
      this.fileUpload.class = 'text-'
      this.loadingFile = true
      this.formCards.url = ''
      const storageRef = this.$root.$data.fb.storage().ref(`/supportFile/${this.form.id}/${this.cardFile.name}`).put(this.cardFile)
      storageRef.on('state_changed', snapshot => {
        const uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      }, error => {
        this.loadingFile = false
        this.fileUpload.text = 'No se pudo subir el archivo. Intente nuevamente'
        this.fileUpload.icon = 'AlertCircleIcon'
        this.fileUpload.class = 'text-danger'
      }, () => {
        this.uploadValue = 100
        storageRef.snapshot.ref.getDownloadURL().then(url => {
          this.fileUpload.text = 'Archivo subido. La url ha sido actualizada'
          this.fileUpload.icon = 'CheckCircleIcon'
          this.fileUpload.class = 'text-success'
          this.loadingFile = false
          // this.$success(this.$t('msg-exito-guardar'))
          // Setear url en firebase
          const myForm = this.form
          this.formCards.url = url
        })
      })
    },
    changeTypeFile(name, value) {
      let bool = true
      let myUrl = ''
      const indexTypefile = this.fields.findIndex(el => el.name === 'typeFile')
      if (value?.length) {
        bool = true
        this.fields[indexTypefile].containerClass = 'container-info col-sm-4 col-md-2 mt-2'
        this.fields.splice(indexTypefile + 1, 0, {name: 'slot1', containerClass: 'container-info col-sm-8 col-md-4 col-lg-4', useSlot: true})
        myUrl = ''
      } else {
        bool = false
        this.fields[indexTypefile].containerClass = 'container-info col-12 col-md-6 mt-2'
        this.fields = this.fields.filter(el => el.name !== 'slot1')
        myUrl = this.form.url
      }
      const index = this.fields.findIndex(el => el.name === 'url')
      this.formCards.url = myUrl
      this.fields[index].disabled = bool
      this.fields[index].clearable = !bool
      this.keyFormRender++
    }
  }
}
</script>

<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Subir';
}
</style>