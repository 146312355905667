<template>
  <div>
    <b-card>
      <form-render :form.sync="form" :key="keyFormRender" :fields.sync="fields" @send="filterCarriers" :buttonSend="buttonSend" containerButtonsClass="col-sm-12 col-md-7 col-lg-2 container-button mt-2"/>
    </b-card>
    <cards v-if="!loading" :sections="sections" :cards="cards"/>
    <cards v-if="loading" :sections="sections" :cards="cards" :onlyLoading="true"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Cards from './Cards.vue'
export default {
  components: {Cards},
  data () {
    return {
      sections: [],
      cards: [],
      loading: true,
      buttonSend: {icon: 'SearchIcon', color: 'warning', title: 'Filtrar'},
      fields: [],
      form: {},
      filters: {},
      keyFormRender: 0
    }
  },
  computed: {
    ...mapGetters({
      globalData: 'getGlobalData',
      carriers: 'getTrackingCarriers',
      // Estos loading se definen en store/global/index.js
      // Se utlizan en el watch para saber cuando el listado fue recargado correctamente
      generalLoading: 'getLoading'
    })
  },
  watch: {
    carriers () {
      this.cards = this.carriers.rows.map(el => {
        const carrier = {
          ...el, 
          id: el.code, 
          title: el.name,
          img: {
            url: `https://storage.googleapis.com/enviame-misc/carriers/logos/color/${el.code.toLowerCase()}.png`,
            width: '200px'
          }, 
          parent: 0
        }
        return carrier
      })
      this.loading = false
    },
    'globalData.countries' () {
      this.setSelectorOptions('country_id', this.globalData?.countries || [])
    }
  },
  mounted () {
    this.sections = [{title: '', id: 0}]
    this.fields = [
      { fieldType: 'FieldSelect', name: 'country_id', label: 'País'}
    ]
    for (let index = 0; index < 16; index++) {
      this.cards.push({ img: 'x', title: 'x', parent: 0, id: index })
    }
    this.setSelectorOptions('country_id', this.globalData?.countries || [])
    this.getCarriers()
  },
  methods: {
    setSelectorOptions (name, options) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].options = options
      this.keyFormRender++
    },
    filterCarriers (form) {
      this.filters = {}
      Object.keys(form).map(key => {
        this.filters[key] = form[key].id || form[key]
      })
      this.getCarriers()
    },
    getCarriers () {
      const queryParams = {
        ...this.filters
      }
      const params = {
      }
      const name = queryParams.country_id ? 'getTrackingCarriers' : 'getTrackingCarriers'
      this.$store.dispatch('fetchService', { name, queryParams, params })
    }
  }
}
</script>
<style lang="scss">
  
</style>