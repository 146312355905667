<template lang="">
  <div id="cards">
    <!-- <b-button @click="loading = !loading; hideSkeleton = !hideSkeleton"/> -->
    <div class="row" v-for="section in sections" :key="section.title">
      <div class="col-sm-12">
        <h3 v-if="section.title" class="mb-1 float-left">{{section.title}}</h3>
        <b-button variant="outline-light" class="ml-1 mb-1" v-if="section.button" @click="section.button.action">
        <feather-icon :icon="section.button.icon"/> {{section.button.text}}</b-button>
      </div>
      <div v-for="(card, index) in myCards.filter(c => c.parent === section.id)" :key="index" class="col-sm-12 col-md-4 col-lg-3 mb-2" v-show="!card.inactive">
        <b-card no-body class="card-support cursor-pointer pt-1 pl-1 pr-1" :bg-variant="card.color">
          <div class="row" style="height: 68%" @click="goToUrl(card, index)">
            <div v-if="(loading || onlyLoading) && (card.icon || card.img)" :class="{'col-sm-12': align !== 'h', 'col-sm-4': align === 'h'}" class="soft-shown" style="height: 100%">
              <b-skeleton-img aspect="16:7"/>
            </div>
            <div v-if="card.title && (loading  || onlyLoading)" :class="{'col-sm-12 mt-1 horizontal-center': align !== 'h', 'text-right col-sm-8 mt-1 pr-2 pb-2': align === 'h'}" class="soft-shown"><b-skeleton :width="align === 'h' ? '100%' : '50%'"/></div>
            <div v-if="card.text && (loading || onlyLoading)" :class="{'col-sm-12 mt-1 horizontal-center': align !== 'h', 'text-right col-sm-8 mt-1 pr-2 pb-2': align === 'h'}" class="soft-shown"><b-skeleton :width="align === 'h' ? '100%' : '90%'"/></div>
            
            <div v-if="card.text && (loading || onlyLoading)" :class="{'col-sm-12 mt-1 horizontal-center': align !== 'h', 'text-right col-sm-8 mt-1 pr-2 pb-2': align === 'h'}" class="soft-shown"><b-skeleton :width="align === 'h' ? '100%' : '60%'"/></div>
            <div v-if="card.icon" :class="{'soft-hidden': !(card.icon && !loading && !onlyLoading), 'col-sm-12': align !== 'h', 'col-sm-4': align === 'h'}" class="text-center icon-container soft-shown">
              <i :class="['lnr', `lnr-${card.icon}`, 'text-light']"/> 
            </div>
            <div :class="{'soft-hidden': !(card.img && !loading && !onlyLoading), 'col-sm-12 horizontal-center': align !== 'h', 'col-sm-4': align === 'h'}" class=" soft-shown" style="height: 100%">
              <b-img :id="`img-${index}`"
              @load="($event) => onLoadImage($event, card)"
              @error="($event) => onErrorImage($event, card)"
              :src="card.img.url" :alt="card.name" :style="{'max-width': '130px', 'max-height': '90px', 'margin': 'auto'}"/>
              <b-img v-if="card.country" :id="`img-flag-${index}`"
              :src="require(`@/assets/images/flags/${card.country}-o.svg`)" :alt="card.country" :class="{'country-image': align !== 'h', 'country-image-horizontal': align === 'h'}"/>
            </div>
            <div :class="{
              'soft-hidden': !(card.title && !loading && !onlyLoading),
              'col-sm-12 text-center mt-1': align !== 'h',
              'col-sm-8 mt-1 pr-2 text-right': align === 'h'
            }" class="soft-shown">
              <h4 :class="card.textColor">{{card.title}}<span v-if="card.country"> {{$t(card.country)}}</span></h4>
            </div>
            <div :class="{
              'soft-hidden': !(card.text && !loading && !onlyLoading),
              'col-sm-12 text-center mt-1': align !== 'h',
              'col-sm-8 mt-1 pr-2 text-right': align === 'h'
              }" class="soft-shown">
              <span :class="card.textColor">{{card.text}}</span>
            </div>
          </div>
            
            <template v-if="showButton">
              <div :class="{'buttons-card': align !== 'h', 'buttons-card-horizontal': align === 'h'}">
                <b-button :disabled="!showModal" variant="outline-light" title="Editar contenido" v-b-tooltip.hover @click="openModal(card)"><feather-icon icon="Edit2Icon"/></b-button>
              </div>
            </template>
        </b-card>
      </div>
    </div>
    <modal-iframe :title="$t('Ver documento')" :url.sync="currentUrl"></modal-iframe>
    <modal-support v-if="showModal" :form.sync="modalForm" :title="modaltitle" @formData="formFields"></modal-support>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalSupport from './ModalSupport.vue'
export default {
  components: {ModalSupport},
  props: ['sections', 'cards', 'onlyLoading', 'showButton', 'align'],
  data () {
    return {
      loadingCard: true,
      loading: true,
      totalImagesLoaded: 0,
      hideSkeleton: false,
      myCards: [],
      modaltitle: '',
      modalForm: {},
      currentUrl: null,
      showModal: false
    }
  },
  computed: {
    ...mapGetters({
      globalData: 'getGlobalData'
    })
  },
  mounted () {
    this.myCards = this.cards
    this.totalImagesLoaded = 0
    this.showModal = Array.isArray(this.globalData?.countries) && this.globalData?.countries.length > 0
  },
  watch: {
    cards () {
      this.myCards = this.cards
    },
    'globalData.countries' () {
      this.showModal = Array.isArray(this.globalData?.countries) && this.globalData?.countries.length > 0
    }
  },
  methods: {
    goToUrl (card, index) {
      if (!card?.url) return
      if (card.url.split('http')[0] !== '') {
        this.$router.push(card.url)
        // } else if (card.newTab) {
        //   window.open(card.url, '_blank')
        // } else {
        //   this.currentUrl = card.url
        //   this.$bvModal.show('modalIFrame')
        // }

      } else {
        let filename = ''
        if (!card.filename) {
          filename = card.url.substring(card.url.indexOf(card.id) + card.id.length + 3, card.url.indexOf('?'))
        } else {
          filename = card.filename
        }
        const images = this.$root.$data.fb.storage().ref().child('supportFile')
        const image = images.child(card.id).child(filename)
        image.getDownloadURL().then((url) => {
          this.$bvModal.show('modalIFrame')
          // This can be downloaded directly:
          const xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = (event) => {
            const blob = xhr.response
            this.currentUrl = URL.createObjectURL(blob)
            // const reader = new FileReader()
            // reader.readAsDataURL(blob) 
            // reader.onloadend = () => {
            //   const base64data = reader.result
            //   this.currentUrl = base64data
            // }
          }
          xhr.open('GET', url)
          xhr.send()

          // Or inserted into an <img> element
          // const img = document.getElementById('myimg')
          // img.setAttribute('src', url)
          // this.currentUrl = url
        })
        // window.open(card.url, '_blank')
      }
    },
    onLoadImage (e, card) {
      this.totalImagesLoaded++
      if (this.myCards.filter(card => card.img).length === this.totalImagesLoaded) {
        this.loading = false
      }
    },
    getBase64Image (img) {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      const dataURL = canvas.toDataURL('image/png')
      return dataURL
    },
    onErrorImage (e, card) {
      this.myCards = this.myCards.map(_card => {
        if (_card.id === card.id) {
          _card.img.url = require('@/assets/images/support/truck.svg')
          /*
          https://image.flaticon.com/icons/png/128/609/609361.png
          https://image.flaticon.com/icons/png/128/754/754850.png
          https://image.flaticon.com/icons/png/128/1169/1169547.png
          https://image.flaticon.com/icons/png/128/3142/3142235.png
          */
        }
        return _card
      })
    },
    formFields(formFields) {
      this.saveContentCard(formFields)
    },
    saveContentCard(formFields) {
      const index = this.cards.findIndex((el) => (el.id === formFields.id))
      this.cards[index] = formFields
    },
    openModal(card) {
      this.modalForm = card
      this.modaltitle = 'Editar Card'
      this.$bvModal.show('modalSupport')
    }
  }
}
</script>
<style lang="scss" scoped>
  @import url('https://cdn.linearicons.com/free/1.0.0/icon-font.min.css');
  .soft-hidden {
    opacity: 0 !important;
    max-height: 0px !important;
    margin-top: 0px !important;
  }
  .soft-shown{
    opacity: 1;
    max-height: 400px;
    transition: opacity 0.5s ease;
  }
  .card-support{
    height: 100% !important;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    transition: all 0.3s ease;
    top: 0px;
    .icon-container {
      display: flex;
      height: 100px;
      > svg {
        margin: auto;
      }
      > i.lnr{
        font-size: 90px;
        margin: auto;
      }
    }
    &:hover {
      top: -3px;
      h5, svg, span, i.lnr {
        color: rgba(var(--vs-primary),1)
      }
    }
     svg, span, i.lnr {
      transition: all 0.5s ease;
    }
  }
  .buttons-card {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .buttons-card-horizontal {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
  .country-image{
    max-width: 30px;
    max-height: 30px;
    bottom: 20px;
    right: 145px;
    position: absolute;
    margin: auto;
  }
  .country-image-horizontal{
    max-width: 30px;
    max-height: 30px;
    bottom: -5px;
    left: 73px;
    position: absolute;
    margin: auto;
  }
  
</style>